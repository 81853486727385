const ARKON_MEMORIES_AJAX_CONTROLLER_URL = location.origin + '/module/arkongrow/ajax';

export default class LikeAction {
    init() {
        this.initLikeButtons();
        this.initLikesEvents();
    }

    initLikeButtons() {
        const likeButtons = document.querySelectorAll('.js-grow-like');

        for(const button of likeButtons) {
            button.addEventListener('click', () => {
                this.onLikeButtonClick(button);
            });
        }
    }

    onLikeButtonClick(button) {
        if(!button || !button.dataset || !button.dataset.grow) {
            return
        }

        this.likeButtonPost(button.dataset.grow);
    }

    likeButtonPost(id_grow) {
        $.ajax({
            type: "POST",
            url: ARKON_MEMORIES_AJAX_CONTROLLER_URL,

            data: {
                id_grow: id_grow,
                action: 'like'
            },

            success: (data) => {
                this.likeButtonPostSuccess(data);
            },
        });
    }

    likeButtonPostSuccess(data) {
        data = JSON.parse(data);
        
        if(!data.success) {
            return;
        }

        document.dispatchEvent(
            new CustomEvent('grow-liked', {detail: {
                grow: data.id_grow, 
                likes: data.likes
            }})
        );
    }

    initLikesEvents() {
        document.addEventListener('grow-liked', (e) => {
            if(!e.detail || !e.detail.grow || !e.detail.likes) {
                return;
            }

            this.onGrowLike(e.detail.grow, e.detail.likes);
        });
    }

    onGrowLike(id_grow, likes) {
        const likedGrow = document.getElementById('arkon-grow-' + id_grow);

        if(!likedGrow) {
            return;
        }
            
        likedGrow.classList.add('arkon-grow--liked');

        const likesCount = likedGrow.querySelector('.js-like-count');

        if(!likesCount) {
            return;
        }

        likesCount.innerHTML = likes;
    }
}